class Elementor_Post_Grid {
    static instance;

    static getInstance() {
        if (!Elementor_Post_Grid.instance) {
            Elementor_Post_Grid.instance = new Elementor_Post_Grid();
        }
        return Elementor_Post_Grid.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-post-grid.default', ($scope) => {
            let $carousel = $scope.find('.owl-carousel');
            if($carousel.length > 0){
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }

        });
    }
}

Elementor_Post_Grid.getInstance();

